import React from 'react'
import { graphql } from 'gatsby'
import Content, { HTMLContent } from '../components/Content'
import Section from '../components/shared/Section'
import PageTitle from '../components/shared/PageTitle'
import Container from '../components/shared/Container'
import Layout from '../components/Layout'
import SEO from '../components/Seo/Seo'

export const ServicePageTemplate = ({ title, content, contentComponent }) => {
  const PostContent = contentComponent || Content

  return (
    <>
      <Section>
        <Container>
          <PageTitle>{title}</PageTitle>
          <PostContent content={content} />
        </Container>
      </Section>
    </>
  )
}

const ServicePage = ({
  data: {
    markdownRemark: {
      frontmatter: { title, description },
      html,
    },
  },
}) => (
  <Layout>
    <SEO description={description} />
    <ServicePageTemplate
      title={title}
      content={html}
      contentComponent={HTMLContent}
    />
  </Layout>
)

export default ServicePage

export const pageQuery = graphql`
  query ServicePageByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        title
        description
      }
    }
  }
`
